<template lang="pug">
//- .loginLayout.loginLayout-container
.layout.layout-tempFix
  routerLink(to="/login-page").loginLayout-logo: img(src="@/assets/logo-en.svg" alt="logo")
  figure(class="auth-page__image"): img(src="@/assets/auth-bg.svg" alt="background")
  slot
  ChatraBtn Не получается войти
</template>

<script>
import ChatraBtn from '@/components/UI/buttons/ChatraBtn.vue'
export default {
  components: {
    ChatraBtn
  }
}
</script>

<style lang="scss">
.loginLayout-container {
  // height: 100vh;
  margin: 0 auto;
  max-width: 1420px;
  padding: 0 20px;
}
.loginLayout-logo {
  display: block;
  // margin-top: 70px;
  margin-bottom: 77px;
}
.layout-tempFix {
  position: relative;
  min-height: 100vh;
  padding-top: 60px;
    max-width: 1180px;
}
</style>

<style lang="scss">
  .auth-page__image {
    position: fixed;
    top: 0;
    right: calc((100vw - 1180px) / 2 );
    z-index: 1;
    height: 100%;
    margin: 0;

    @include media-down($breakpoint-xs) {
      display: none;
    }

    img {
      width: 100%;
      height: 100vh;
    }
  }
</style>