<template lang="pug">
BaseBtn(size="sm" type="outline" @click="isModalActive = true").chatraBtn
  slot
  ChatraModal(v-model="isModalActive")
</template>
  
<script>
import ChatraModal from '@/components/modals_new/filled/ChatraModal.vue';
export default {
  data(){
    return {
      isModalActive: false,
    }
  },
  components : {ChatraModal}
}
</script>

<style lang="scss">
.chatraBtn {
  margin-top: 30px;
  background: transparent !important;
  
  .base-btn__content {
    &:before {
      content: '';
      height: 6px;
      width: 6px;
      border-radius: 100%;
      background-color: $yellow ;
    }
  }
  &:not(:hover) {
    color: $grey-4 !important;
    border-color: $grey-5 !important;
  }
  &:hover {
    color: $grey-2 !important;
    border-color: $accent-color !important;
  }
}
</style>