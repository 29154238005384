<template lang="pug">
MessageModal(size="md" v-bind="$attrs" v-on="$listeners" rowButtons)
  template(#subtitle)
    .h3(style="text-align:center") Возникли проблемы со входом в приложение?
  template(#body)
    span Попробуйте сначала воспользоваться нашей базой знаний, в которой мы подробно описали весь процесс авторизации и возможные нюансы 🙂 
    span Если вы все сделали правильно, но это не помогло,  то напишите нам в чат — мы оперативно вам поможем!
  template(#buttons)
    //- BaseBtn(href="https://labstory.notion.site/bde5352d40de42218c4de25160826337" size="sm" style="width: 200px") База знаний
    BaseBtn(href="https://www.labstori.ru/pacientam/getresult/" target="_blank" size="sm" style="width: 200px") Войти в старый кабинет
    BaseBtn(@click="handlerOpenChatra" size="sm" type="outline") Написать нам
</template>
  
<script>
import {openChatra} from '@/services/chatra.js'
import MessageModal from '@/components/modals_new/templates/MessageModal.vue';
export default {
  components : {MessageModal},
  methods: {
    handlerOpenChatra() {
      openChatra();
      this.$emit('input', false)
    }
  }
}
</script>
